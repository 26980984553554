import {
  BYO_STEP_TITLE_MAPPING,
  CANONICAL_BASTING_MAPPING,
  CANONICAL_CHOICE_MAPPING,
  CANONICAL_SIDES,
  DIETARY_STATUS_LOOKUP_INVERSE,
  DIETARY_TYPES,
} from '@nandosaus/constants';
import {
  camelCase,
  compact,
  filter,
  find,
  flatMap,
  get,
  includes,
  isEmpty,
  isNumber,
  map,
  reduce,
  reject,
  some,
  toLower,
} from 'lodash';
import { getRoot, types } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';

import { groupMenuItemsByGroupName } from '../../util/group-menu-items-by-group-name';
import { kilojoulesFormatter } from '../../util/formatter';
import Option from '../option';

const Choice = types
  .model('Choice', {
    id: types.optional(types.identifier, uuidv4),
    type: types.optional(types.string, ''),
    name: '',
    maximumOptionQuantity: 0,
    maximumOptionLimit: 0,
    minimumOptionLimit: 0,
    options: types.optional(types.array(Option), []),
    defaultSelectedOptions: types.array(types.safeReference(Option)),
  })
  .views(self => ({
    /**
     * Used on a correspondingChoice to find one of it's options that best matches
     * the option passed in by first using the plu and secondly the name.
     * Returns undefined if no corresponding option could be found.
     */
    correspondingOption({ plu, name }) {
      const { options } = self;
      let option;

      option = find(options, { plu });
      if (option) {
        return option;
      }

      option = find(options, { name });
      if (option) {
        return option;
      }

      return option;
    },

    optionById(id) {
      return self.options.find(option => option.id === id);
    },

    groupsForSelectedItems(items, selectedItems) {
      const otherSizesForItems = compact(flatMap(items, 'otherSizes'));
      const selectedOtherSizes = filter(otherSizesForItems, otherSize => includes(selectedItems, otherSize.value));
      const openGroups = compact(map(selectedOtherSizes, 'groupName'));
      return reduce(openGroups, (result, value) => ({ ...result, [value]: true }), {});
    },

    get isSide() {
      const { canonicalName } = self;
      const containsSideInName = /side/i.test(canonicalName);
      return CANONICAL_SIDES.includes(canonicalName) || containsSideInName;
    },

    get canonicalName() {
      return find(CANONICAL_CHOICE_MAPPING, (value, key) => toLower(key) === toLower(self.name)) || self.name;
    },

    get byoStepTitle() {
      return find(BYO_STEP_TITLE_MAPPING, (value, key) => toLower(key) === toLower(self.name)) || self.name;
    },

    doesNotMeetDietaryPreferences(option) {
      const { DietaryPreferencesStore } = getRoot(self);
      const productDietaryData = get(option, 'suitability');

      const check = map(DIETARY_TYPES, dietaryOption => {
        const productDietaryOption = get(productDietaryData, camelCase(dietaryOption));
        if (isEmpty(get(productDietaryOption, 'range'))) {
          return undefined;
        }

        const dietaryPreferencesStoreDietaryOptionValue =
          DIETARY_STATUS_LOOKUP_INVERSE[DietaryPreferencesStore[camelCase(dietaryOption)]];

        return some(productDietaryOption.range, value => value >= dietaryPreferencesStoreDietaryOptionValue);
      });

      return includes(check, false);
    },

    get optionsForPicker() {
      return self.options.map(option => {
        const text = option.prices.cents === 0 ? null : `+ ${option.prices.formattedPrice}`;

        return {
          text,
          label: option.name,
          value: option.id,
          image: option.image,
          disabled: !option.isAvailable || self.doesNotMeetDietaryPreferences(option),
          isEligibleForDeliveryDiscount: option.isEligibleForDeliveryDiscount,
        };
      });
    },

    get optionsForMealPicker() {
      const items = map(self.options, option => {
        const price = option.prices.cents === 0 ? null : option.prices.formattedPrice;
        const text = !option.groupName && price ? `+ ${price}` : null;
        const disabled = !option.isAvailable || self.doesNotMeetDietaryPreferences(option);

        return {
          text,
          price,
          kilojoules: isNumber(option.kilojoulesValue) ? kilojoulesFormatter(option.kilojoulesValue) : null,
          label: option.groupName ? option.size : option.name,
          value: option.id,
          groupName: option.groupName,
          otherSizes: option.otherSizes,
          image: option.image,
          disabled,
          isEligibleForDeliveryDiscount: option.isEligibleForDeliveryDiscount,
        };
      });

      const optionsWithOtherSizes = groupMenuItemsByGroupName({ items, uniqueItemsOnly: true });

      return map(optionsWithOtherSizes, option => {
        const hasAvailableOtherSizes = option.groupName && some(reject(option.otherSizes, 'disabled'));
        return { ...option, disabled: option.disabled && !hasAvailableOtherSizes };
      });
    },

    get required() {
      if (self.minimumOptionLimit > 0) {
        return true;
      }

      const { ProductDetailsState } = getRoot(self);
      const { name } = self;

      if (
        CANONICAL_BASTING_MAPPING[name] &&
        ProductDetailsState &&
        !ProductDetailsState.isProteinThatHidesBastingSelected
      ) {
        return true;
      }

      return false;
    },

    /**
     * Returns true if a dietary filter is active, and the choice has some unsuitable options.
     */
    get hasDisabledOptions() {
      const { DietaryPreferencesStore } = getRoot(self);

      return (
        !isEmpty(DietaryPreferencesStore.dietaryPreferencesList) &&
        self.optionsForPicker.filter(option => option.disabled).length > 0
      );
    },

    get otherSizesIdsToGroupNames() {
      const otherSizes = compact(flatMap(self.optionsForMealPicker, 'otherSizes'));
      return reduce(otherSizes, (result, otherSize) => ({ ...result, [otherSize.value]: otherSize.groupName }), {});
    },

    getOptionIdsToGroupNames(selectedOptionIds) {
      return map(selectedOptionIds, optionId => get(self.otherSizesIdsToGroupNames, optionId, optionId));
    },
  }));

/**
 * Maps a choiceName to a canonical choice name.
 * If undefined, falls back to the choice name.
 */
Choice.getCanonicalName = choiceName => {
  return find(CANONICAL_CHOICE_MAPPING, (value, key) => toLower(key) === toLower(choiceName)) || choiceName;
};

export default Choice;
