import { CANONICAL_CHOICE_MAPPING } from '@nandosaus/constants';
import { includes, some, toLower } from 'lodash';
import { getRoot, types } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';

import Prices from '../prices';

const Option = types
  .model('Option', {
    description: '',
    id: types.optional(types.identifier, uuidv4),
    name: '',
    plu: types.optional(types.string, ''),
    points: 0,
    prices: types.compose(Prices),
    sortOrder: types.maybe(types.number),
    groupName: types.maybeNull(types.string),
    size: types.maybeNull(types.string),
    kilojoules: types.maybeNull(types.number),
    suitability: types.frozen(),
    isAvailable: types.optional(types.boolean, true),
    image: types.maybeNull(types.string),
    isEligibleForDeliveryDiscount: types.optional(types.boolean, false),
    hidesBastingIfSelected: types.optional(types.boolean, false),
  })
  .views(self => {
    const { FeatureFlagStore } = getRoot(self);

    return {
      get isNutritionOption() {
        const { Style, Protein } = CANONICAL_CHOICE_MAPPING;
        const style = toLower(Style);
        const protein = toLower(Protein);

        return some([style, protein], nutritionOption => includes(self.id, nutritionOption));
      },

      get kilojoulesValue() {
        if (!FeatureFlagStore.featureFlags['nutritional-data']) {
          return undefined;
        }

        return self.kilojoules;
      },
    };
  });

export default Option;
