import { Amplify, API, Auth, Hub } from 'aws-amplify';
import { InMemoryCache } from '@aws-amplify/cache';
import { replace } from 'lodash';

const getAuthStorage = () => {
  if (typeof window === 'undefined') {
    return InMemoryCache;
  }
  try {
    // @NOTE: use localStorage by default so we can remember user devices
    // If this becomes a security concern then we need to use sessionStorage by default but that will trigger more 2FA SMS messages.
    return window.localStorage || window.sessionStorage || InMemoryCache;
  } catch (error) {
    return InMemoryCache;
  }
};

const getCacheStorage = () => {
  if (typeof window === 'undefined') {
    return InMemoryCache;
  }

  try {
    return window.sessionStorage || InMemoryCache;
  } catch (error) {
    return InMemoryCache;
  }
};

const awsConfig = {
  Auth: {
    region: process.env.cognito.region,
    userPoolId: process.env.cognito.userPoolId,
    userPoolWebClientId: process.env.cognito.clientId,
    authenticationFlowType: 'USER_SRP_AUTH',
    oauth: {
      domain: process.env.cognito.clientId,
      scope: process.env.cognito.scope,
      redirectSignIn: process.env.cognito.callbackUrls,
      redirectSignOut: process.env.cognito.callbackUrls,
      responseType: 'code',
    },
    storage: getAuthStorage(),
  },
  Analytics: {
    disabled: true,
  },
  Cache: {
    keyPrefix: `${replace(process.env.web.url, /^http(s)?:\/\//, '')}.`,
    storage: getCacheStorage(),
  },
  withCredentials: true,
};

Amplify.configure(awsConfig);

export { API, Auth, Hub, InMemoryCache };
