import { camelCase, filter, flatMap, get, includes, isEmpty, map } from 'lodash';
import {
  DIETARY_STATUS,
  DIETARY_STATUS_LOOKUP,
  DIETARY_TYPES,
  MENU_CATEGORIES,
  ORDER_TYPES,
} from '@nandosaus/constants';
import { flow, getEnv, getRoot, types } from 'mobx-state-tree';
import { reaction, when } from 'mobx';

import { formatBytes } from '../../util/format-bytes';
import { getDocument } from './get-document';
import { getMenu } from './get-menu';
import Menu from '../../models/menu';
import { isMealModifier } from '../../helpers/check-product-modifier';

const AGE_VERIFIED = 'AGE_VERIFIED';

// @NOTE: the implementation of this function needs to be consistent with
// createProductId() in apps/api/lib/data-sources/au/product/mapping.js
const createMenuId = ({ restaurantId, orderType }) => {
  return `${orderType || ORDER_TYPES.PICK_UP}-${restaurantId}`;
};

const convertApiValueToStoreValue = suitabilityList =>
  map(suitabilityList, value => {
    return DIETARY_STATUS_LOOKUP[value];
  });

const checkDietaryPreferences = (productSuitability, optionSelected, usersDietaryPreference) => {
  const suitabilityList = get(productSuitability, optionSelected).range;
  const convertedSuitabilityList = convertApiValueToStoreValue(suitabilityList);

  if (usersDietaryPreference === DIETARY_STATUS.YES) {
    // Returns true if the product is either a strict match or a partial match
    return (
      includes(convertedSuitabilityList, DIETARY_STATUS.STRICT) ||
      includes(convertedSuitabilityList, DIETARY_STATUS.YES)
    );
  }

  return true;
};

const initialState = {};

/**
 * The MenuStore maintains a map of Menu models keyed by restaurant id.
 * The App and Website should typically rely on MenuStore.menu as the active menu.
 *
 * NOTE: when OrderingContextStore.restaurant is updated, the MenuStore will respond and
 * load + update to reflect the newly selected OrderingContextStore.restaurant's menu.
 */
const MenuStore = types
  .model('MenuStore', {
    allergenPDF: types.maybe(types.frozen()),
    loading: false,
    error: false,
    scrollYValueMobile: 0,
    scrollCategory: types.maybe(types.string),
    menus: types.map(Menu),
    isAllergenPDFLoading: false,
    ageVerified: false,
  })
  .actions(self => {
    const { cmsContext, getApiClient, logger, localStorage } = getEnv(self);

    const syncWithMenuPreferences = async () => {
      if (self.preferences === undefined || self.hasMenu(self.preferences)) {
        return;
      }

      await self.loadMenu(self.preferences);
    };

    /**
     * Observe menu preferences and load corresponding menus when not yet loaded.
     */
    reaction(
      () => self.preferences,
      () => {
        syncWithMenuPreferences();
      },
      { fireImmediately: process.env.CI !== 'true' }
    );

    return {
      setAgeVerified: async value => {
        self.ageVerified = Boolean(value);
        await localStorage.setItem(AGE_VERIFIED, value);
      },

      async afterCreate() {
        const value = await localStorage.getItem(AGE_VERIFIED);
        self.setAgeVerified(value);
      },

      loadMenu: flow(function*(menuToLoad = self.preferences) {
        // Avoid the same menu being loaded in quick succession by running loadMenu in series
        yield when(() => !self.loading);

        const hasMenu = self.hasMenu(menuToLoad);
        const menu = self.getMenu(menuToLoad);

        // If the menu has already been loaded recently, no need to load it again
        if (hasMenu && !menu.isStale()) {
          return;
        }

        self.error = false;
        self.loading = true;

        try {
          const client = yield getApiClient();
          const result = yield getMenu({ ...menuToLoad, client, loadDetails: true });
          const { data, error } = result;

          if (error) {
            throw error;
          }

          const menuData = {
            ...data,
            // use id returned from the API where possible
            // return createMenuId to avoid breaking tests
            id: data.id || createMenuId(menuToLoad),
            categories: data.categories.map(category => ({
              ...category,
              products: category.products
                .filter(product => !isMealModifier(product.modifier))
                .map(product => product.id),
            })),
            products: flatMap(data.categories, 'products'),
          };

          if (hasMenu) {
            menu.update(menuData);
          } else {
            self.menus.put(menuData);
          }

          if (self.menu) {
            self.menu.groupProductSizes();
          }
        } catch (error) {
          // @TODO: Move away from error being a boolean.
          logger.error('Failed to load menu', { ...menuToLoad, error: JSON.stringify(error) });
          self.error = true;
        }
        self.loading = false;
      }),

      fetchAllergenPDF: flow(function*() {
        // If the PDF has already been loaded, no need to load it again
        if (self.allergenPDF || self.isAllergenPDFLoading) {
          return;
        }

        try {
          self.isAllergenPDFLoading = true;
          const client = yield getApiClient();
          const result = yield getDocument({ client, document: 'allergenPDF', siteId: cmsContext.siteId });
          const { data, error } = result;

          if (error) {
            throw error;
          }

          self.allergenPDF = get(data, 'allergenPDF[0]');
        } catch (error) {
          logger.warn('CMS: Failed to fetch allergen PDF', { error });
        }

        self.isAllergenPDFLoading = false;
      }),

      setError(displayError) {
        self.error = displayError;
      },

      setScrollYValue(scrollYValue) {
        self.scrollYValueMobile = scrollYValue;
      },
      setScrollCategory(category) {
        self.scrollCategory = category;
      },
    };
  })
  .views(self => {
    const { defaultMenuRestaurantId } = getEnv(self);
    const { DietaryPreferencesStore, FeatureFlagStore, OrderingContextStore } = getRoot(self);

    return {
      get allergenLink() {
        if (!self.allergenPDF) return null;

        const { url, filename, size } = self.allergenPDF;

        return {
          url,
          filename,
          size: formatBytes(size),
        };
      },

      // @NOTE: Leaving this on MenuStore, because self.menu could be null,
      // which means MenuStore.menu.categoryNames as a getter would fail.
      get categoryNames() {
        if (self.filteredMenu === undefined) {
          return [];
        }

        const categories = get(self.filteredMenu, 'categories');
        const filteredCategories = filter(categories, category => !isEmpty(get(category, 'products')));

        return map(filteredCategories, 'name');
      },

      /**
       * Get the user's current menu preferences (order type & restaurant)
       */
      get preferences() {
        // Ensure we can test parts of this model in isolation.
        if (OrderingContextStore === undefined) {
          return undefined;
        }

        if (!OrderingContextStore.restaurantId) {
          // Load the default menu
          return { restaurantId: defaultMenuRestaurantId, orderType: ORDER_TYPES.PICK_UP };
        }

        return { restaurantId: OrderingContextStore.restaurantId, orderType: OrderingContextStore.orderType };
      },

      /**
       * Get the menu which corresponds with current menu preferences.
       * Will return undefined if the menu hasn't been loaded.
       */
      get menu() {
        if (self.preferences === undefined) {
          return undefined;
        }

        if (self.hasMenu(self.preferences)) {
          return self.getMenu(self.preferences);
        }

        return undefined;
      },

      get filteredMenu() {
        if (self.menu === undefined) {
          return undefined;
        }

        const menuItems = {
          categories: self.menu.categories
            .filter(category => {
              const alcoholPurchaseEnabled = FeatureFlagStore.isActive('purchase-alcohol');
              const isAlcoholCategory = category.handle === MENU_CATEGORIES.ALCOHOL;
              const isWesternAustralianRestaurant = get(OrderingContextStore, 'restaurant.address.state') === 'WA';
              const isByoCategoryEnabled = FeatureFlagStore.isActive('build-your-own-category');
              const isByoCategory = category.handle === MENU_CATEGORIES.BYO;

              if (!isByoCategoryEnabled && isByoCategory) {
                return false;
              }

              if (!alcoholPurchaseEnabled && isAlcoholCategory) {
                return false;
              }

              // Disable the alcohol category for pick up orders in Western Australian
              if (isAlcoholCategory && isWesternAustralianRestaurant && OrderingContextStore.isPickUp) {
                return false;
              }

              return true;
            })
            .map(category => ({
              ...category,
              products: category.products.filter(product => {
                // If Dietary Preferences is not active then skip filtering of menu
                if (!FeatureFlagStore.isActive('dietary-preferences')) {
                  return true;
                }

                if (category.name === 'Drinks') {
                  return true;
                }

                const meetsPreferences = map(DIETARY_TYPES, type => {
                  return checkDietaryPreferences(
                    product.suitability,
                    camelCase(type),
                    DietaryPreferencesStore[camelCase(type)]
                  );
                });

                // Check returned all true
                const checker = arr => arr.every(Boolean);

                return checker(meetsPreferences);
              }),
            })),
        };

        return menuItems;
      },

      get dietaryPreferredFeaturedProducts() {
        if (self.menu === undefined) {
          return [];
        }
        return !isEmpty(DietaryPreferencesStore.dietaryPreferencesList)
          ? filter(self.menu.featuredProducts, product => !isEmpty(product.formattedDietaryPreferencesList))
          : self.menu.featuredProducts;
      },

      hasMenu({ restaurantId, orderType }) {
        const key = createMenuId({ restaurantId, orderType });
        return self.menus.has(key);
      },

      getMenu({ restaurantId, orderType }) {
        const key = createMenuId({ restaurantId, orderType });
        return self.menus.get(key);
      },

      // Same as getProductById but works without knowing the menuId.
      getProductByPartialId(partialId, menu = self.menu) {
        if (menu === undefined) {
          return undefined;
        }

        const id = `${menu.id}-${partialId}`;
        return self.getProductById(id, menu);
      },

      /**
       * Searches through the menu for the Product (by id)
       * Returns the first matching instance of Product model when found
       * Returns undefined if no matching product could be found
       * @param {Product.identifier} id
       * @param {Menu} menu
       */
      getProductById(id, menu = self.menu) {
        if (menu === undefined) {
          return undefined;
        }

        return menu.getProductById(id);
      },

      /**
       * Searches through the menu for the Category containing Product (by id)
       * Returns the first matching instance of Category model when found
       * Returns undefined if no matching category could be found
       * @param {Product.identifier} id
       * @param {Menu} menu
       */
      getProductCategory(id, menu = self.menu) {
        if (menu === undefined) {
          return undefined;
        }

        return menu.getProductCategory(id);
      },

      getProductNutrition(PLUs, menu = self.menu) {
        if (menu === undefined) {
          return undefined;
        }

        return menu.getProductNutrition(PLUs);
      },

      getScrollYValue() {
        return self.scrollYValueMobile;
      },
    };
  });

MenuStore.initialState = initialState;

export default MenuStore;
