const yup = require('yup');
const { webAndmobileFlagsData } = require('../web-and-mobile');
const { variationSchema } = require('../common');

const improvedOrderConfirmationScreen = Object.freeze({
  launchDarklyKey: 'improved-order-confirmation-flow',
  yupSchema: yup.boolean().meta({
    description: 'Enables the new order confirmation flow',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const payWithGiftCard = Object.freeze({
  launchDarklyKey: 'pay-with-gift-card',
  yupSchema: yup.boolean().meta({
    description: 'Enables paying with Gift cards',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const orderSummary = Object.freeze({
  launchDarklyKey: 'order-summary',
  yupSchema: yup.boolean().meta({
    description: 'Enables Active Order UI in order summary',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const orderAtTablePromotionsMobile = Object.freeze({
  launchDarklyKey: 'order-at-table-promotions',
  yupSchema: yup
    .array(
      yup.object({
        numberOfDescriptionLines: yup.number().integer(),
        buttonLink: yup.lazy(value =>
          yup
            .string()
            .url()
            .isValidSync(value)
            ? yup.string().url()
            : yup.string().oneOf(['Menu', 'Home', 'Profile', 'Offers', 'More'])
        ),
        header: yup.string().required(),
        textWidth: yup
          .string()
          .matches(/^[0-9]+%$/, { message: 'Only numeric characters and percetange are allowed.' }),
        image: yup.string().required(),
        disclaimer: yup.string().nullable(),
        description: yup.string().nullable(),
        buttonText: yup.string().required(),
        variant: variationSchema,
      })
    )
    .meta({
      description: 'This controls the content of the promo carousel.',
    }),
  variants: [
    {
      description: 'off',
      value: [],
    },
    {
      // This variant is here to test coverage
      description: 'Staging - AU',
      value: [
        {
          numberOfDescriptionLines: 3,
          buttonLink: 'Menu',
          header: 'Spin to Win + $0 Delivery',
          buttonLinkParams: {
            category: 'Spin to Win',
          },
          textWidth: '60%',
          image: 'https://assets.nandosaus.inlight.fun/promocards_spin_to_win_au-1.png',
          disclaimer: null,
          description: 'Order a Coke product for 1-in-4 chances to win & $0 DELIVERY! T&Cs apply.',
          buttonText: 'Order now',
        },
        {
          numberOfDescriptionLines: 3,
          buttonLink: 'https://nandosaus.inlight.fun',
          header: 'Spin to Win + $0 Delivery',
          buttonLinkParams: {
            category: 'Spin to Win',
          },
          textWidth: '60%',
          image: 'https://assets.nandosaus.inlight.fun/promocards_spin_to_win_au-1.png',
          disclaimer: null,
          description: 'Order a Coke product for 1-in-4 chances to win & $0 DELIVERY! T&Cs apply.',
          buttonText: 'Order now',
        },
      ],
    },
  ],
});

const digitalGiftCard = Object.freeze({
  launchDarklyKey: 'digital-gift-card',
  yupSchema: yup
    .boolean()
    .required()
    .meta({ description: 'Enabled digital gift card in the app' }),
  variants: [{ description: 'off state', value: false }],
});

const orderSettingsOnMenuScreen = Object.freeze({
  launchDarklyKey: 'show-order-settings-on-menu-screen',
  yupSchema: yup
    .boolean()
    .required()
    .meta({
      description:
        'Direct the user to the order settings screen (from the menu screen), if they have not selected order settings yet',
    }),
  variants: [{ description: 'off state', value: false }],
});

module.exports = {
  mobileFlagsData: Object.freeze({
    ...webAndmobileFlagsData,
    [orderSettingsOnMenuScreen.launchDarklyKey]: orderSettingsOnMenuScreen,
    [digitalGiftCard.launchDarklyKey]: digitalGiftCard,
    [orderAtTablePromotionsMobile.launchDarklyKey]: orderAtTablePromotionsMobile,
    [orderSummary.launchDarklyKey]: orderSummary,
    [payWithGiftCard.launchDarklyKey]: payWithGiftCard,
    [improvedOrderConfirmationScreen.launchDarklyKey]: improvedOrderConfirmationScreen,
  }),
};
