import Script from 'next/script';

import { useFeature } from '@hooks/use-feature';

const Zendesk = () => {
  const { url } = useFeature('zendesk-chat') || {};
  const enabled = !!url;

  if (!enabled) {
    return null;
  }

  return <Script id="ze-snippet" src={url} />;
};

export { Zendesk };
