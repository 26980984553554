import { useFeature } from '@hooks/use-feature';
import { LivePerson } from './live-person';
import { Zendesk } from './zendesk';

const LiveChat = () => {
  const isLivePersonEnabled = useFeature('enable-liveperson');
  const { url } = useFeature('zendesk-chat');
  const isZendeskChatEnabled = !!url;

  if (!isLivePersonEnabled && !isZendeskChatEnabled) {
    return null;
  }

  return (
    <>
      {isLivePersonEnabled && <LivePerson />}
      {isZendeskChatEnabled && <Zendesk />}
    </>
  );
};

export { LiveChat };
